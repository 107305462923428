import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container my-5">
      <h2 className="text-center mb-4 text-primary fw-bolder">Privacy Policy</h2>
      <div className="card p-4">
        <p>
          At SAN Planners & Designers, we respect your privacy and are committed to protecting your personal data.
        </p>

        <h5 className="text-primary">1. Information We Collect</h5>
        <p>
          We collect personal information such as your name, contact number, city, village, category, and message through our Contact Us form. For internship applications, we collect your email, name, contact, position applied for, resume, and cover letter.
        </p>

        <h5 className="text-primary">2. How We Use Your Information</h5>
        <p>
          We use the collected information for communication, responding to inquiries, and processing internship applications. Your data is not shared with third parties without your consent.
        </p>

        <h5 className="text-primary">3. Third-Party Images</h5>
        <p>
          Our website contains images obtained from free licensed sources. Any third-party images displayed are owned by their respective owners. We do not claim ownership of any third-party images.
        </p>

        <h5 className="text-primary">4. Data Security</h5>
        <p>
          We take appropriate security measures to protect your personal data from unauthorized access, alteration, or disclosure.
        </p>

        <h5 className="text-primary">5. Cookies</h5>
        <p>
          Our website may use cookies to enhance user experience. You can disable cookies in your browser settings if you prefer not to allow them.
        </p>

        <h5 className="text-primary">6. Policy Updates</h5>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be reflected on this page.
        </p>

        <h5 className="text-primary">7. Contact Us</h5>
        <p>
          If you have any questions regarding our Privacy Policy, please contact us at:
        </p>
        <p>
          <strong className="text-primary">Email:</strong> sanplannersperundurai@gmail.com <br />
          <strong className="text-primary">Phone:</strong> +91 73054 69679 | 80725 40319 | 63822 69679 <br />
          <strong className="text-primary">Address:</strong> SAN Planners & Designers, No.14/1, 2nd Floor, VSM Complex, Kanjikovil Road, Near Anna Silai, Perundurai, Erode – 638 052.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
