import { Route, Routes, useLocation } from 'react-router-dom';
import i18n from './i18n';
import { useState, useEffect, useContext } from 'react';
import Header from './components/blocks/Headercopy copy';
import './css/App.css';
import './css/main.css';

import * as bootstrap from 'bootstrap';

import Logo from './assets/SAN Planners Logo cropped.png'
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import About from './components/pages/About';
import Projects from './components/pages/Projects';
import Contact from './components/pages/Contact';
import NotFound from './components/pages/NotFound';
import Footer from './components/blocks/OldFooter';
import Careers from './components/pages/Careers';
import HeaderAccordion from './components/blocks/CustomAccordion'

import Blueprint from './components/pages/services/Blueprint';
import DTCP from './components/pages/services/DTCPLayout';
import Design3D from './components/pages/services/Design3D';
import Design2D from './components/pages/services/Design2D';
import StructuralDesign from './components/pages/services/StructuralDesign';
import Construction from './components/pages/services/Construction';
import Estimation from './components/pages/services/Estimation';
import Documentation from './components/pages/services/Documentation';
import FMB from './components/pages/services/FMB';

import BlueprintProjects from './components/pages/projects/Blueprint';
import DtcpProjects from './components/pages/projects/Dtcp';
import InteriorProjects from './components/pages/projects/Interior';
import ExteriorProjects from './components/pages/projects/Exterior';
import Design2DProjects from './components/pages/projects/Design2D';
import FMBProjects from './components/pages/projects/Fmb';
import { Dcontext } from './context/DataContext';
import Design from './components/pages/services/Design';
import Carousel from './components/blocks/Carousel';
import ServiceZones from './components/ServiceZones';
import TestSlider from './components/blocks/TestimonialsV2';
import Terms from './components/pages/Terms';
import Privacy from './components/pages/Privacy';

function App() {

  const {modalRef, renderImage, closeRenderedImg} = useContext(Dcontext)

  const [modelBox, setModelBox] = useState(null)
  const [ln, setLn] = useState('en')
  const [mountAccordion, setMountAccordion] = useState(true)


  const closeOffcanvas = () => {
    const offcanvasElement = document.getElementById('offcanvasExample');
    const offcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement); // Get the existing instance
    if (offcanvas) {
      offcanvas.hide(); // Close it
    }

    const backdrop = document.querySelector('.offcanvas-backdrop');
    if (backdrop) {
      backdrop.remove();
    }

  };

  const changeLanguage = (lng) => {
    setLn(lng)
    i18n.changeLanguage(lng);
    modelBox.hide()
  };

  useEffect(()=>{
    const modal = new window.bootstrap.Modal(document.getElementById('myModal'), {
        backdrop: 'static'
    });
    setModelBox(modal)
    // modal.show();
  },[])

  useEffect(()=>{
    if(modalRef.current){
        modalRef.current.classList.add('d-none')
    }
  },[])


  const location = useLocation();

  useEffect(() => {
    const containerElement = document.getElementById("app-container")


    if(containerElement){
      containerElement.scrollIntoView()
    }

  }, [location.pathname]);





  return (
    <div className='container-fluid p-0 bg-white position-relative app-container' id='app-container'>
      <div className='position-sticky top-0 w-100' style={{zIndex: '1004'}}>
        <Header ln={ln} changeLanguage={changeLanguage} setMountAccordion={setMountAccordion} />
      </div>

      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/service-zones' element={<ServiceZones/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/projects' element={<Projects/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/internship' element={<Careers/>} />


        <Route path='/blueprint-approvals' element={<Blueprint/>} />
        <Route path='/dtcp-layout' element={<DTCP/>} />
        <Route path='/design' element={<Design/>} />
        <Route path='/design-3d' element={<Design3D/>} />
        <Route path='/design-2d' element={<Design2D/>} />
        <Route path='/structural-design' element={<StructuralDesign/>} />
        <Route path='/construction' element={<Construction/>} />
        <Route path='/estimation' element={<Estimation/>} />
        <Route path='/documentation' element={<Documentation/>} />
        <Route path='/fmb' element={<FMB/>} />

        <Route path='/test' element={<TestSlider/>} />


        <Route path='/project-blueprint' element={<BlueprintProjects/>} />
        <Route path='/project-dtcp' element={<DtcpProjects/>} />
        <Route path='/project-interior' element={<InteriorProjects/>} />
        <Route path='/project-exterior' element={<ExteriorProjects/>} />
        <Route path='/project-2d' element={<Design2DProjects/>} />
        <Route path='/project-fmb' element={<FMBProjects/>} />

        <Route path='/terms-condition' element={<Terms/>} />
        <Route path='/privacy-policy' element={<Privacy/>} />


        <Route path='*' element={<NotFound/>} />



      </Routes>

      <Footer/>

      {/* <div className='header-translation p-2 rounded-start position-fixed bottom-0 end-0 bg-light opacity-80' style={{zIndex: '1002'}}>
          <h5>Translate page/ மொழிபெயர்க்க?</h5>
          <button className={`btn fw-bold ${ln==='en'?'btn-dark':'btn-outline-dark'}`} onClick={()=>changeLanguage('en')}><i className='bi bi-translate'></i> English</button>
          <button className={`btn fw-bold ${ln==='tn'?'btn-dark':'btn-outline-dark'}`} onClick={()=>changeLanguage('tn')}><i className='bi bi-translate'></i> தமிழ்</button>
      </div> */}


      <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" data-bs-backdrop="static" data-bs-scroll="true">
        <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
        <div className='text-center' style={{width: 'fit-content'}}>
            <img src={Logo} alt='SAN' style={{height: '60px'}} />
            </div>
        </h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>setMountAccordion(false)}></button>
        </div>
        <div className="offcanvas-body">
          {mountAccordion&&<HeaderAccordion closeFunc={closeOffcanvas} ln={ln} changeLanguage={changeLanguage}/>}
        </div>
      </div>


          {/* Modal */}
          <div className="modal fade" id="myModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Translate page/ மொழிபெயர்க்க?</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <button className={`btn m-2 fw-bold ${ln==='en'?'btn-dark':'btn-outline-dark'}`} onClick={()=>changeLanguage('en')}><i className='bi bi-translate'></i> English</button>
                        <button className={`btn m-2 fw-bold ${ln==='tn'?'btn-dark':'btn-outline-dark'}`} onClick={()=>changeLanguage('tn')}><i className='bi bi-translate'></i> தமிழ்</button>
                    </div>
                </div>
            </div>
        </div>




        <div ref={modalRef} className="p-0 position-fixed modal-container top-0 w-100" style={{zIndex: '1008'}}>
          <div className='d-flex align-items-center justify-content-center h-100 w-100'>
            {
              renderImage!==null?
              // <img className='w-100 h-100 object-fit-contain' src={renderImage} alt='Project Image'/>
              <div className='w-100 h-100'>
                <Carousel/>
              </div>
              :<p>Loading...</p>
            }
          </div>
          <span className="material-symbols-outlined fs-1 m-3 text-light position-absolute top-0 end-0 bg-primary rounded-circle" onClick={closeRenderedImg} style={{zIndex: '1008'}} role='button'>
            cancel
            </span>
          <div className='text-center h-20 position-fixed bottom-0 start-50 translate-middle'>
            <button className='btn btn-light border-dark fs-5 px-4 rounded-pill m-2 border' onClick={closeRenderedImg}>Close</button>
          </div>
      </div>

      <div className='bg-light rounded-circle whatsapp-container' onClick={()=>window.open(`https://wa.me/917305469679?text=Hello%20SAN%20Planners%20%26%20Designers%20team!%20I%E2%80%99m%20interested%20in%20learning%20more%20about%20your%20services.%20Could%20you%20please%20provide%20me%20with%20more%20details%3F%20Thank%20you!`,'_blank')}>
        <i className='bi bi-whatsapp fs-1 text-success mx-2'></i>
      </div>


    </div>
  );
}

export default App;
