import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const CustomAccordion = (props) => {

    const navigatePage = useNavigate()

    useEffect(()=>{
        return()=>{
            setExpandedMenu(null)
            setExpandedSubMenu(null)
            setExpandedChildSubMenu(null)
        }
    },[])

    const menuItems = [
        {
          title: 'Home',
          path: '/',
          icon: 'home',
        },
        {
          title: 'About Us',
          path: '/about',
          icon: 'source_environment',
        },
        {
          title: 'Services',
          subMenu: [
            {
              title: 'Blueprint Approvals',
              subMenu: [
                { title: 'Building Plan Approval', path: '/blueprint-approvals' },
                { title: 'Online Blueprint', path: '/blueprint-approvals' },
                { title: 'Building & Industry Layout', path: '/blueprint-approvals' },
              ],
            },
            {
              title: 'DTCP Layouts & Approvals',
              subMenu: [
                { title: 'DTCP Layout Plan Approval', path: '/dtcp-layout' },
                { title: 'DTCP Unapproved Layout Plan Approval', path: '/dtcp-layout' },
              ],
            },
            {
              title: 'Designs (3D & 2D)',
              subMenu: [
                {
                  title: '3D Design',
                  subMenu: [
                    { title: 'Architectural Design', path: '/design-3d' },
                    { title: '3D Elevation Design & Walkthrough', path: '/design-3d' },
                    { title: 'Interior', path: '/design-3d' },
                    { title: 'Exterior', path: '/design-3d' },
                  ],
                },
                {
                  title: '2D Design',
                  subMenu: [
                    { title: 'Vastu Plan', path: '/design-2d' },
                    { title: 'Plumbing and Electrical Design', path: '/design-2d' },
                  ],
                },
              ],
            },
            {
              title: 'Structural Design',
              subMenu: [
                { title: 'Residential/ Commercial Structures', path: '/structural-design' },
                { title: 'Industrial/ Steel Structures', path: '/structural-design' },
                { title: 'Reinforced Concrete Structures', path: '/structural-design' },
                { title: 'Green Certification Building Structures', path: '/structural-design' }
              ], 
            },
            {
              title: 'Construction',
              subMenu: [
                { title: 'Project Management', path: '/construction' },
                { title: 'Construction Consulting', path: '/construction' },
                { title: 'Consultations', path: '/construction' },
              ],
            },
            {
              title: 'Estimating & Costing',
              subMenu: [
                { title: 'Building Valuation', path: '/estimation' },
                { title: 'Bank Estimations', path: '/estimation' },
              ],
            },
            {
              title: 'Document',
              subMenu: [
                { title: 'Online EC, SRO EC (Encumbrance Certificate)', path: '/documentation' },
                { title: 'Patta Name Transfer', path: '/documentation' },
                { title: 'SRO Deed Copy', path: '/documentation' },
                { title: 'Non-Encumbrance Certificate (NEC)', path: '/documentation' },
              ],
            },
            {
              title: 'FMB (Sub division)',
              subMenu: [
                { title: 'FMB Drafting', path: '/fmb' },
                { title: 'Topo Sketch (Topographical Sketch)', path: '/fmb' },
                { title: 'Land Subdivision', path: '/fmb' },
                { title: 'Land Surveying', path: '/fmb' },
                { title: 'Surveyor Map - FMB (Field Measurement Book)', path: '/fmb' },
                { title: 'Survey Plan', path: '/fmb' },
              ],
            },
          ],
          icon: 'linked_services',
          path: '/services'
        },
        {
          title: 'Projects',
          path: '/projects',
          icon: 'view_comfy_alt',
          subMenu: [
            {
              title: 'Blueprint',
              path: '/project-blueprint'
            },
            {
              title: 'DTCP',
              path: '/project-dtcp'
            },
            {
              title: 'Interior Designing',
              path: '/project-interior'
            },
            {
              title: 'Exterior Designing',
              path: '/project-exterior'
            },
            {
              title: '2D Design',
              path: '/project-2d'
            },
            {
              title: 'FMB',
              path: '/project-fmb'
            }
          ]
        },
        {
          title: 'Service Zones',
          subMenu: [
            {
              title: 'Corporation (1+)',
              path: '/service-zones',
            },
            {
              title: 'Municipality (3+)',
              path: '/service-zones',
            },
            {
              title: 'DTCP - District Offices (5+)',
              path: '/service-zones',
            },
            {
              title: 'Taluka (7+)',
              path: '/service-zones',
            },
            {
              title: 'Union Offices (6+)',
              path: '/service-zones',
            },
            {
              title: 'Sub-Registrar Office (14+)',
              path: '/service-zones',
            },
            {
              title: 'Town Panchayat (14+)',
              path: '/service-zones',
            },
            {
              title: 'Villages (143+)',
              path: '/service-zones',
            }
          ],
          icon: 'activity_zone',
          path: '/service-zones'
        },
        {
          title: 'Internship',
          path: '/internship',
          icon: 'work',
        },
        {
          title: 'Contact Us',
          path: '/contact',
          icon: 'contacts_product',
        },
    ];

    const [ExpandedMenu, setExpandedMenu] = useState(null)
    const [ExpandedSubMenu, setExpandedSubMenu] = useState(null)
    const [ExpandedChildSubMenu, setExpandedChildSubMenu] = useState(null)



    const handleMenuExpansion = (menuIndex) =>{
        setExpandedMenu(prev=>{
            const prevValue = prev
            if(menuIndex===prevValue){
                return null
            }
            else{
                return menuIndex
            }
        })
        if(menuItems[menuIndex].path!=='/services'&&menuItems[menuIndex].path!=='/projects'&&menuItems[menuIndex].path!=='/service-zones'){
            navigatePage(menuItems[menuIndex].path)
            props.closeFunc()
        }
    }

    const handleSubMenuExpansion = (menu, submenuIndex) =>{
      console.log('calledd',menu, submenuIndex)

      if(menu.subMenu[submenuIndex].path){
        navigatePage(menu.subMenu[submenuIndex].path)
        props.closeFunc()
      }
      else{
        setExpandedSubMenu(prev=>{
          const prevValue = prev
          if(submenuIndex===prevValue){
              return null
          }
          else{
              return submenuIndex
          }
        })
      }
    }

    const handleChildSubMenuExpansion = (subsubmenuIndex) =>{
        setExpandedChildSubMenu(prev=>{
            const prevValue = prev
            if(subsubmenuIndex===prevValue){
                return null
            }
            else{
                return subsubmenuIndex
            }
        })
    }


    const handlePageNavigation = (path) => {
        navigatePage(path)
        setExpandedMenu(null)
        setExpandedSubMenu(null)
        setExpandedChildSubMenu(null)
        props.closeFunc()
    }

    const handleTranslation = (ln) => {
        props.changeLanguage(ln)
        navigatePage('/')
        setExpandedMenu(null)
        setExpandedSubMenu(null)
        setExpandedChildSubMenu(null)
        props.closeFunc()
    }


  return (
    <div>
        {
            menuItems.map((menu, menuIndex)=>{
                return(
                    <div key={menuIndex}>
                        <div className='accordion-items accordion-items-parent rounded' onClick={()=>handleMenuExpansion(menuIndex)}>
                            <div className='d-flex align-items-center gap-3'>
                                <span className="bg-light rounded-circle p-2 material-symbols-outlined fs-1">{menu.icon}</span>
                                {menu.title}
                            </div>
                            {menu.subMenu&&<span className="material-symbols-outlined ms-auto">{ExpandedMenu===menuIndex?'arrow_drop_up':'arrow_drop_down'}</span>}
                        </div>
                        {
                            (ExpandedMenu===menuIndex&&menu.subMenu)&&menu.subMenu.map((submenu, submenuIndex)=>{
                                return(
                                    <div className='ms-4' key={submenuIndex}>
                                        <div className='rounded accordion-items accordion-items-child' onClick={()=>handleSubMenuExpansion(menu, submenuIndex)}>
                                            {submenu.title}
                                            {submenu.subMenu&&<span className="material-symbols-outlined ms-auto">{ExpandedSubMenu===submenuIndex?'arrow_drop_up':'arrow_drop_down'}</span>}
                                        </div>
                                        {
                                            (ExpandedSubMenu===submenuIndex&&submenu.subMenu)&&submenu.subMenu.map((subsubmenu, subsubmenuIndex)=>{
                                                return(
                                                    <div className='ms-4' key={subsubmenuIndex}>
                                                        <div className='rounded accordion-items accordion-items-grandchild' onClick={()=>handleChildSubMenuExpansion(subsubmenuIndex)}>
                                                            <span onClick={()=>{subsubmenu.path&&handlePageNavigation(subsubmenu.path)}}>{subsubmenu.title}</span>
                                                            {subsubmenu.subMenu&&<span className="material-symbols-outlined ms-auto">{ExpandedChildSubMenu===subsubmenuIndex?'arrow_drop_up':'arrow_drop_down'}</span>}
                                                        </div>
                                                        {
                                                            (ExpandedChildSubMenu===subsubmenuIndex&&subsubmenu.subMenu)&&subsubmenu.subMenu.map((childsubsubmenu, childsubsubmenuIndex)=>{
                                                                return(
                                                                    <div className='ms-4 rounded accordion-items accordion-items-grandchild-child' key={childsubsubmenuIndex}>
                                                                        <span onClick={()=>{childsubsubmenu.path&&handlePageNavigation(childsubsubmenu.path)}}>{childsubsubmenu.title}</span>
                                                                        {childsubsubmenu.subMenu&&<span className="material-symbols-outlined ms-auto">arrow_drop_down</span>}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            })
        }
        {/* <div className='border rounded p-2'>
            <h5>Translate page/ மொழிபெயர்க்க?</h5>
            <button className={`btn m-2 fw-bold ${props.ln==='en'?'btn-dark':'btn-outline-dark'}`} onClick={()=>handleTranslation('en')}><i className='bi bi-translate'></i> English</button>
            <button className={`btn m-2 fw-bold ${props.ln==='tn'?'btn-dark':'btn-outline-dark'}`} onClick={()=>handleTranslation('tn')}><i className='bi bi-translate'></i> தமிழ்</button>
        </div> */}
    </div>
  )
}

export default CustomAccordion