import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container my-5">
      <h2 className="text-center mb-4 text-primary">Privacy Policy</h2>
      <p>Your privacy is important to us. This policy explains how we handle and protect your personal data.</p>

      <h5 className="text-primary">1. Information We Collect</h5>
      <p>
        - <strong className="text-primary">Contact Form:</strong> Name, phone number, city, village, category, and message.
        <br />
        - <strong className="text-primary">Internship Form:</strong> Redirects to Google Forms where we collect email, name, phone number, position, resume, and cover letter.
      </p>

      <h5 className="text-primary">2. How We Use Your Information</h5>
      <p>
        We use the collected information to:
        <br />
        - Respond to inquiries and provide services.
        <br />
        - Process internship applications.
        <br />
        - Improve our website and user experience.
      </p>

      <h5 className="text-primary">3. Data Protection</h5>
      <p>
        - Your personal data is not shared with third parties without consent.
        <br />
        - We use industry-standard security measures to protect your information.
      </p>

      {/* <h5 className="text-primary">4. External Links</h5>
      <p>
        Our website may contain links to third-party websites (such as Google Forms). We are not responsible for their privacy policies.
      </p> */}

      <h5 className="text-primary">4. Updates to Policy</h5>
      <p>
        We may update this privacy policy from time to time. Users are advised to check this page regularly for any changes.
      </p>

      <p className="mt-4">
        For privacy concerns, contact us at <strong>sanplannersperundurai@gmail.com</strong> or call us at
        <strong> +91 73054 69679 | 80725 40319 | 63822 69679</strong>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
