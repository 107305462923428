import React, { useContext, useEffect, useRef } from "react";
import { Dcontext } from "../../context/DataContext";

function TestSlider() {

  const { clientFeedback } = useContext(Dcontext);


  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;

    // Clone the slides for seamless looping
    const cloneSlides = [...slider.children].map((slide) =>
      slide.cloneNode(true)
    );
    cloneSlides.forEach((slide) => slider.appendChild(slide));
  }, [clientFeedback]);


  const animationPause = () => {
    sliderRef.current.style.animationPlayState = "paused";
  }

  const runAnimation = () => {
    sliderRef.current.style.animationPlayState = "running";
  }

  return (
      <div className="slider-container">
          <div className="sliders" ref={sliderRef} onTouchStart={animationPause} onTouchCancel={runAnimation} onTouchEnd={runAnimation} onMouseOver={animationPause} onMouseOut={runAnimation}>
          {clientFeedback.map((review, i) => (
            <div className="testimonials-card" key={i}>
              <div className="testimonials-img-container d-flex justify-content-center align-items-center">
                <img
                  className="testimonials-img my-3"
                  src={`https://ui-avatars.com/api/?name=${review.imgname}&background=36BCFA&color=072e4a`}
                  alt={review.title}
                />
              </div>
                  <div className="text-center">
                      <h3 className="text-primary my-2">{review.name}</h3>
                      <h4 className="display-6 fs-5 my-2 fw-bold">{review.project}</h4>
                    {/* <span className="text-warning fs-4">
                      {(() => {
                        let stars = [];
                        for (let i = 1; i <= 5; i++) {
                          if (review.rating >= i) {
                            stars.push(
                              <i key={i} className="bi bi-star-fill"></i>
                            );
                          } else {
                            stars.push(<i key={i} className="bi bi-star"></i>);
                          }
                        }
                        return stars;
                      })()}
                    </span> */}
                </div>
                <p className=" m-0"><i className="bi bi-quote"></i>{review.message}<i className="bi bi-quote quote-icon-end"></i></p>
            </div>
          ))}
        </div>
      </div>
  );
}

export default TestSlider;
