import React from "react";
import GandhiImg from '../../assets/Gandhi Sir.jpeg'
import JeyaImg from '../../assets/Jeyalakshmi.jpg'

const teamMembers = [
  {
    name: "Gandhi Krishnamoorthy",
    designation: "Lead Design Engineer",
    imgSrc: GandhiImg
  },
  {
    name: "Jeyalakshmi B",
    designation: "3D Moduler",
    imgSrc: JeyaImg // Replace with actual image paths
  },
];

const TeamMembers = () => {
  return (
    <div className="container my-5">
      <h2 className="text-center fw-bolder text-primary my-3">Meet Our Team</h2>
      <div className="row justify-content-center">
        {teamMembers.map((member, index) => (
          <div key={index} className="col-md-4 col-sm-6 team-member">
            <div className="team-card">
              <div className="team-img-container">
                <img src={member.imgSrc} alt={member.name} className="team-img" />
              </div>
              <h5 className="team-name text-primary">{member.name}</h5>
              <p className="team-role">{member.designation}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamMembers;
